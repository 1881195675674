<template>
  <div class="root">
    <Uploader/>
  </div>
</template>

<script>
import Uploader from "@/common/Uploader";

export default {
  name: "UploaderView",
  components: { Uploader }
}
</script>

<style scoped>

</style>