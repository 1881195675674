<!--suppress JSUnresolvedVariable -->
<template>
  <div class="root">
    <input type="file" ref="fileUploader" id="fileUploader" :contenteditable="finished"/>
    <el-button @click="handleUploadButtonClicked" :disabled="!finished">Upload</el-button>
    <el-button @click="handlePauseButtonClicked" v-if="!paused">Pause</el-button>
    <el-button @click="handleResumeButtonClicked" v-else>Resume</el-button>
    <el-progress style="margin-top: 30px" :percentage="percentage" :text-inside="true" :stroke-width="40"
                 :format="percentageFormat"/>
  </div>
</template>

<script>
import OSS from "ali-oss";
import {v4 as uuid} from 'uuid';
import network from "@/network";

let abortCheckpoint;
let file;
let filePath;
let bodyJson;
export default {
  name: "Uploader",
  data() {
    return {
      percentage: 0,
      client: null,
      paused: false,
      finished: true
    }
  },
  created() {
    network.get('/OSS/sts').then(res => {
      let token = res.data;
      this.client = new OSS({
        region: 'oss-cn-chengdu',
        accessKeyId: token.AccessKeyId,
        accessKeySecret: token.AccessKeySecret,
        stsToken: token.SecurityToken,
        bucket: 'my-video-platform',
        refreshSTSToken: async () => {
          const refreshToken = await network.get('/OSS/sts');
          return {
            accessKeyId: refreshToken.AccessKeyId,
            accessKeySecret: refreshToken.AccessKeySecret,
            stsToken: refreshToken.SecurityToken,
          };
        },
        refreshSTSTokenInterval: 600000
      });
    })
  },

  methods: {
    percentageFormat(percentage) {
      if (percentage === 0) return '0MB';
      return percentage === 100 ? 'Upload Finished' : `${Math.round(percentage * file.size / 100 / 1024 / 1024)}MB/${Math.round(file.size / 1024 / 1024)}MB`;
    },

    handleUploadButtonClicked() {
      file = this.$refs.fileUploader.files[0];
      if (file === undefined) {
        this.$message.error('Please select a file first!');
        return;
      }

      // start new upload
      this.finished = false;
      let fileId = uuid();
      filePath = `/private/${fileId}/${file.name}`;
      // reset the percentage and checkpoint
      bodyJson = {
        id: fileId,
        name: file.name,
        path: filePath
      };
      // do the upload
      this.percentage = 0;
      this.client.multipartUpload(filePath, file, {
        callback: {
          url: 'https://back.videos.gkers.top/api/OSS/upload_callback',
          body: JSON.stringify(bodyJson),
          contentType: 'application/json',
        },
        progress: (percentage, cpt) => {
          abortCheckpoint = cpt;
          this.percentage = percentage * 100;
        },
        headers: {
          "Content-Disposition": fileId
        },
      }).then(res => {
        // console.log(res);
        this.finished = true;
      }).catch(err => {
        console.log(err);
        if (err.name !== 'cancel')
          this.finished = true;
        this.finished = true;

      })
    },

    handlePauseButtonClicked() {
      this.client.cancel();
      this.paused = true;
    },

    handleResumeButtonClicked() {
      this.paused = false;
      this.client.multipartUpload(filePath, file, {
        callback: {
          url: 'https://back.videos.gkers.top/api/OSS/upload_callback',
          body: JSON.stringify(bodyJson),
          contentType: 'application/json',
        },
        checkpoint: abortCheckpoint,
        progress: (percentage, cpt) => {
          abortCheckpoint = cpt;
          this.percentage = percentage * 100;
        },
      }).then(() => {
        this.finished = true;
      }).catch(err => {
        console.log(err);
        if (err.name !== 'cancel')
          this.finished = true;
      })
    }
  }

}
</script>

<style scoped>


</style>